class MapMarkerData {
    constructor () {
        this.html = null

        //region 기본 정보
        /** 이름 **/
        this.name = null
        /** 부서 이름 **/
        this.depatmentName = null
        /** 데이터 고유 아이디 **/
        this.id = null
        /** 좌표 lng  **/
        this.lng = null
        /** 좌표 lat  **/
        this.lat = null
        /** 마커 방향 값 **/
        this.rotate = 0
        /** 메인 여부  **/
        this.isMain = false
        /** 맵 아이디 **/
        this.mapId = null
        /** 이미지 이름 **/
        this.imageName = null
        /** 보여짐 여부 **/
        this.isShow = true
        /** 맵 데이터 타입 **/
        this.mapDataType = null
        //endregion

        //region 드론 정보
        /** 거리 **/
        this.distance = 0
        /** 짐볼 각도 **/
        this.gimbalDirection = null
        /** 고도 **/
        this.height = 0
        //endregion

        //region 도시 표현 데이터
        /** 칼라 **/
        this.color = null
        //endregion

        //region 채널 사용자 라이브 데이터
        /** region 사용자 라이브 여부 **/
        this.isUserLive = false
        //endregion

        //region 라이브 맵 데이터
        /** 라이브맵 좌표 ( rightTop, leftBottom) **/
        this.liveMapBounds = null
        /** 라이브 맵 열화상 이미지 색상 데이터(제거 조건) **/
        this.colors = null
        //endregion

        //region 포인트 데이터
        /** 이름 **/
        this.userName = null
        /** 포인트 필요한 추가 데이터 **/
        this.pointType = null

        /**  owner 소유자 **/
        this.isOwner = false

        /**  포인트 상세 데이터 **/
        this.pointDetail = null

        /**  포인트 상세 정보 수정 **/
        this.editMode = false
        //endregion

        //region senser info KCTENC
        this.sensorInfo = null
        //endregion
    }

    setPointData(pointData){
        this.id = pointData.index;
        this.lng = pointData.lng;
        this.lat = pointData.lat;
        this.pointType = pointData.type;
        this.isOwner = pointData.isOwner;
        this.editMode = pointData.editMode;

        this.pointDetail = pointData.memo;
        this.name = pointData.name;
        this.userName = pointData.registerUserName;
        this.userDepartmentName = pointData.registerUserDepartmentName;
    }

    setDroneData(droneData){
        this.id = droneData.selectId;
        this.lng = droneData.lng;
        this.lat = droneData.lat;
        this.name = droneData.deviceName;
        this.rotate = droneData.azimuth;
        this.gimbalDirection = droneData.gimbalDirection;
        this.distance = droneData.distance;
        this.height = droneData.height;
        this.userName = droneData.userName;
        this.userDepartmentName = droneData.userDepartmentName;
    }

    setUserData(userData){
        this.id = userData.selectId;
        this.lng = userData.lng;
        this.lat = userData.lat;
        this.name = userData.name
    }

    setStationData(userData){
        this.id = userData.selectId;
        this.lng = userData.lng;
        this.lat = userData.lat;
    }

    setSensorData(sensor) {
        this.id = sensor.selectId;
        this.name = "(Meerkat)"+ sensor.name;
        this.lng = sensor.lng;
        this.lat = sensor.lat;
        this.sensorInfo = sensor;
    }

    setCCTVData(cctvData) {
        this.id = cctvData.selectId;
        this.name = cctvData.assignedUserName;
        this.lng = cctvData.lng;
        this.lat = cctvData.lat;
    }
}

export default MapMarkerData;
